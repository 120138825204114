var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "LBL0002548", collapsed: true },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.editable && _vm.equipInfo.machineryClassificationName
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "LBL0002500",
                            value: _vm.equipInfo.machineryClassificationName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.machineryName
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "LBL0002507",
                            value: _vm.equipInfo.machineryName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.emplacementPlace
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "제조번호",
                            value: _vm.equipInfo.emplacementPlace,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.ewrNo
                  ? _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "형식번호",
                            value: _vm.equipInfo.ewrNo,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "tableImpr",
              attrs: {
                title: "LBL0002571",
                columns: _vm.gridImpr.columns,
                data: _vm.equipInfo.equipmentImproveModels,
                merge: _vm.gridImpr.merge,
                gridHeight: _vm.gridImpr.height,
                selection: "multiple",
                rowKey: "equipImproveId",
                usePaging: false,
                filtering: false,
                columnSetting: false,
                editable: _vm.editable,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "equipImproveId",
                                ibmTaskTypeCd:
                                  props.row.equipTypeCd === "1"
                                    ? "ITT0000055"
                                    : "ITT0000050",
                                ibmTaskUnderTypeCd:
                                  props.row.equipTypeCd === "1"
                                    ? "ITTU000085"
                                    : "ITTU000075",
                              },
                              on: { imprChange: _vm.imprChange },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLADD",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.equipInfo.equipmentImproveModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "LBLREMOVE",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      _vm.equipInfo.equipmentImproveModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              url: _vm.insertUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.equipInfo.equipmentImproveModels,
                              mappingType: "POST",
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveImprove,
                              btnCallback: _vm.saveCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }